import { BPSizes, Colors, ColumnsGap, StyledH1, StyledH5 } from 'commons/Theme'
import styled from 'styled-components'
import { AtomLink } from '../atoms/AtomLink'

export const StyledAvvisoTitleWrapper = styled.div<{ $hasImage?: boolean }>`
  padding-top: 64px;
  padding-bottom: ${({ $hasImage }) => ($hasImage ? '0' : '24px')};
  background-color: ${Colors.blue100};
  position: relative;
  line-height: 1.3;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    padding-top: 80px;
    padding-bottom: ${({ $hasImage }) => ($hasImage ? '0' : '60px')};
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    padding-top: 120px;
    line-height: 1.35;
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    padding-bottom: ${({ $hasImage }) => ($hasImage ? '0' : '120px')};
  }
`

export const StyledAvvisoComunicazioneLogo = styled.div`
  max-width: 200px;
  img {
    width: 100%;
    height: auto;
  }
  margin-bottom: 24px;
  order: -1;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    order: initial;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-bottom: 0px;
  }
`

export const StyledCategoriaDateWrapper = styled.div`
  margin-bottom: 16px;
`

export const StyledAvvisoTitle = styled(StyledH1)`
  max-width: 626px;
  margin: 0;
`

export const StyledAvvisoSubtitle = styled(StyledH5)`
  margin: 0;
  margin-top: 8px;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-top: 16px;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-top: 24px;
  }
`

export const StyledAvvisoBody = styled.div`
  margin-bottom: inherit;
`

export const StyledAvvisoBodyWrapper = styled.div`
  background: ${Colors.white};
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 1440px; */
  padding-left: ${ColumnsGap.sm};
  padding-right: ${ColumnsGap.sm};

  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  @media screen and (min-width: ${BPSizes.md}px) {
    padding-left: ${ColumnsGap.md};
    padding-right: ${ColumnsGap.md};
  }
  @media screen and (min-width: ${BPSizes.lg}px) {
    padding-left: ${ColumnsGap.lg};
    padding-right: ${ColumnsGap.lg};
  }
`

export const StyledAvvisoImage = styled.div`
  img {
    margin-top: 0px;
    max-height: 560px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: ${BPSizes.tablet}px) {
      margin-top: 40px;
    }
  }
`

export const StyledAvvisiCTAWrapper = styled.div`
  margin-top: 40px;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-top: 60px;
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    margin-top: 120px;
  }
`

export const StyledAccediButtonWrapper = styled.div`
  justify-self: flex-start;
  width: fit-content;
  margin-bottom: 24px;

  @media screen and (min-width: ${BPSizes.md}px) {
    justify-self: flex-end;
    margin-bottom: 0;
  }
`

export const StyledAccediButton = styled(AtomLink)`
  order: -1;
  justify-self: flex-end;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    order: initial;
  }
`

export const StyledAvvisiAddToCalendarWrapper = styled.div`
  margin-top: 24px;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-top: 33px;
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    margin-top: 40px;
  }

  .eventi-add-to-calendar-x431f {
    svg {
      width: 16px;
    }
  }
`
